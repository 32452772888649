"use client";

import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { currencies } from "@prisma/client";

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

const CurrencySelector = (props: {
  currencies: {
    catalogType: string;
    currencyList: currencies[];
    selectedCurrency: currencies | null;
    country: string;
  };
}) => {
  const [selected, setSelected] = useState(props.currencies.selectedCurrency);

  const changeSelector = (newEl: currencies) => {
    setSelected(newEl);
    window.location.href = `/${props.currencies.catalogType}/${props.currencies.country}/${newEl.slug}`;
  };

  const removeSelection = () => {
    window.location.href = `/${props.currencies.catalogType}/${props.currencies.country}`;
  };

  return (
    <Listbox value={selected} onChange={changeSelector}>
      {({ open }) => (
        <>
          <div className="flex text-xs md:text-md flex-col relative mt-1">
            <Listbox.Label className="flex flex-col md:flex-row justify-between block text-sm font-medium text-gray-700 mb-1 lg:my-4 w-full">
              <span className="flex">Filtrar por divisa</span>{" "}
              {selected ? (
                <>
                  <button
                    onClick={removeSelection}
                    className="flex text-primary"
                  >
                    Borrar selección
                  </button>
                </>
              ) : (
                <span className="block md:hidden">&nbsp;</span>
              )}{" "}
            </Listbox.Label>
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-1 lg:pl-3 pr-10 text-left shadow-sm  focus:outline-none focus:ring-1 focus:ring-secondary sm:text-sm">
              <span className="flex items-center">
                {selected ? (
                  <>
                    <span className="ml-3 text-gray-700 block truncate">
                      {selected.title_es}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="ml-3 text-gray-700 block truncate">
                      Selecciona
                    </span>
                  </>
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {props.currencies.currencyList.map((currency: currencies) => (
                  <Listbox.Option
                    key={currency.slug}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-primary" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={currency}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {currency.title_es}
                          </span>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default CurrencySelector;
