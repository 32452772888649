"use client";

import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import type { countries } from "@prisma/client";

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

const CountrySelector = (props: {
  countries: {
    countryList: countries[];
    selectedCountry: countries | null;
    catalogType: any;
  };
}) => {
  const catalogTypeList = [
    {
      id: 1,
      title_es: "Monedas",
      slug: "catalogo",
    },
    {
      id: 2,
      title_es: "Billetes",
      slug: "billetes",
    },
  ];
  const [selected, setSelected] = useState(
    catalogTypeList.find((el) => el.slug === props.countries.catalogType)
  );

  const changeSelector = (newEl: any) => {
    setSelected(newEl);
    window.location.href = props.countries.selectedCountry
      ? `/${newEl.slug}/${props.countries.selectedCountry.slug}`
      : `/${newEl.slug}`;
  };

  return (
    <Listbox value={selected} onChange={changeSelector}>
      {({ open }) => (
        <>
          <div className="flex text-xs md:text-md flex-col relative mt-1">
            <Listbox.Label className="flex flex-col md:flex-row justify-between block text-sm font-medium text-gray-700 mb-1 lg:my-4 w-full">
              <span className="flex">Filtrar por tipo</span>{" "}
              <span className="block md:hidden">&nbsp;</span>
            </Listbox.Label>
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm  focus:outline-none focus:ring-1 focus:ring-secondary sm:text-sm">
              <span className="flex items-center">
                {selected ? (
                  <>
                    <span className="ml-3 text-gray-700 block truncate">
                      {selected.title_es}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="ml-3 text-gray-700 block truncate">
                      Selecciona un tipo
                    </span>
                  </>
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-1">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {catalogTypeList.map((type) => (
                  <Listbox.Option
                    key={type.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-primary" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={type}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {type.title_es}
                          </span>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default CountrySelector;
