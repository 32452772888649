"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useForm } from "react-hook-form";

interface IFormInputs {
  yearFilter?: string | null;
}

const YearFilterInput = () => {
  const removeSelection = () => {
    window.location.href = `${pathname}`;
  };

  const pathname = usePathname();
  const searchparams = useSearchParams();
  const yearFilter = searchparams?.get("yearFilter");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    defaultValues: { yearFilter: yearFilter },
  });
  const onSubmit = (formData: IFormInputs) => {
    window.location.href = `${pathname}?page=1&yearFilter=${formData.yearFilter}`;
  };

  return (
    <>
      <div className="flex text-xs md:text-md flex-col relative mt-1">
        <label className="flex flex-col md:flex-row justify-between block text-sm font-medium text-gray-700 mb-1 lg:my-4 w-full">
          <span className="flex">Filtrar por año</span>{" "}
          {yearFilter ? (
            <>
              <button onClick={removeSelection} className="flex text-primary">
                Borrar selección
              </button>
            </>
          ) : (
            <span className="block md:hidden">&nbsp;</span>
          )}{" "}
        </label>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"></div>
              <input
                {...register("yearFilter", {
                  required: true,
                  pattern: /^(1\d{3}|20\d{2})$/,
                })}
                type="number"
                className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-1 lg:pl-5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:outline-none focus:ring-2 focus:ring-secondary sm:text-sm sm:leading-6"
                placeholder={yearFilter ? "Cargando..." : "1970"}
              />
            </div>
            <button
              type="submit"
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
            >
              Filtrar
            </button>
          </div>
          <span className="text-xs">
            {errors.yearFilter && "No es un año válido"}
          </span>
        </form>
      </div>
    </>
  );
};
export default YearFilterInput;
